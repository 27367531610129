<template>
  <div class="loginBox">
    <div class="loginForm">
      <!-- <img src="~@/assets/images/logo.png"/> -->
      <van-field
        v-model="form.phone"
        type="number"
        clearable
        center
        :left-icon="require('@/assets/img/huaxia/phone.png')"
        placeholder="请输入手机号码"
      />
      <van-field
        v-model="form.captchaCode"
        center
        clearable
        :left-icon="require('@/assets/img/huaxia/yzm.png')"
        placeholder="请输入短信验证码"
      >
        <template #button>
          <!-- <van-button size="small" type="primary">发送验证码</van-button> -->
          <div v-if="!timer" @click="getCodeMeds">发送验证码</div>
          <van-count-down v-else :time="timer" @finish="timer = 0" />
        </template>
      </van-field>
      <van-field
        v-model="form.bankCardNo"
        type="number"
        clearable
        center
        :left-icon="require('@/assets/img/huaxia/phone.png')"
        placeholder="请输入银行卡号"
      />
      <van-button
        class="formButton"
        loading-text="加载中..."
        :loading="loading"
        @click="submit()"
      >
        校验权益
      </van-button>
    </div>
    <div class="rule">
      <img src="@/assets/img/huaxia/title.png" alt="" />

      <div>
        一、活动时间<br />
        2025年2月15日至2025年6月30日（具体合作开展时间以合同签订为准）
      </div>
      <div>
        二、活动对象<br />
        华夏银行信用卡持卡客户（卡片状态为正常）
      </div>
      <div>
        三、活动内容<br />
        活动期间,华夏银行信用卡持卡人至与家装、家电、手机3C等参与国补合作商户消费，通过银联分期二维码，选择华夏银行信用卡分期支付，分期金额满4000元立减200元。名额有限,先到先得，额满为止。活动期间，每位持卡人限享1次优惠。
      </div>
    </div>
  </div>
</template>
<script>
import { getCaptcha, login } from '@/api/huaxia'
export default {
  data() {
    return {
      form: {
        phone: undefined,
        captchaCode: undefined
      },
      timer: 0,
      loading: false
    }
  },
  methods: {
    async getCodeMeds() {
      const reg = /^1[3456789]\d{9}$/
      if (!this.form.phone && !reg.test(this.form.phone)) {
        this.$toast.fail('请填写正确的手机号')
        return
      }
      const { code } = await getCaptcha({
        phone: this.form.phone
      })
      if (code === 0) {
        this.timer = 60000
      }
    },
    async submit() {
      try {
        const form = this.form
        if (!form.phone || !form.captchaCode || !form.bankCardNo) {
          this.$toast.fail('手机号、验证码、银行卡不能为空')
          return
        }
        this.loading = true
        const { code, data } = await login(form)
        this.loading = false
        if (code === 0) {
          sessionStorage.setItem('huaxiaToken', data)
          this.$router.push({
            name: 'huaxiaRights',
            query: {
              phone: form.phone
            }
          })
        }
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.loginBox {
  min-height: 100vh;
  background: #fce7bd url('~@/assets/img/huaxia/bg.png') top center no-repeat;
  background-size: 100% auto;
  .loginForm {
    padding: 65% 38px 0px;
    .van-cell {
      background: #f5f7fd;
      border-radius: 20px;
      margin-bottom: 15px;
      &::after {
        border-bottom: none;
      }
    }
    .formButton {
      margin: 30% auto 35px;
      font-size: 16px;
      color: #fff1df;
      height: 40px;
      line-height: 40px;
      background: #030001;
      border-radius: 50px;
      text-align: center;
      cursor: pointer;
      width: 100%;
      display: block;
    }
  }
  .rule {
    margin: 37px 26px 0px;
    padding-bottom: 2px;
    img {
      display: block;
      width: 130px;
      margin: 0 auto 20px;
    }
    & > div {
      margin-bottom: 10px;
      font-size: 12px;
      color: #030001;
      line-height: 18px;
    }
  }
}
</style>
