import request from '@/utils/request'
import apiConfig from '@/api/apiconfig'
/**
 * 验证码
 */
export function getCaptcha(data) {
  return request({
    url: `${apiConfig.ZY}/hbkRightBiz/getCaptcha`,
    method: 'post',
    data
  })
}
/**
 * 检验-获取token
 */
export function login(data) {
  return request({
    url: `${apiConfig.ZY}/hbkRightBiz/check`,
    method: 'post',
    data
  })
}
/**
 * 获取权益信息
 */
export function getRightInfo(data) {
  return request({
    url: `${apiConfig.ZY}/hbkRightBiz/getRightInfo/${sessionStorage.getItem('huaxiaToken')}`,
    method: 'post',
    data
  })
}
/**
 * 兑换领取
 */
export function huaxiaExchange(data) {
  return request({
    url: `${apiConfig.ZY}/hbkRightBiz/exchange`,
    method: 'post',
    data
  })
}
